import React, { useEffect, useState } from 'react';
import Loader from '../loader/Loader.component';

declare global {
  interface Window {
    Silverexpress: any;
  }
}

interface SilverExpressFormProps {

  result: {
    type?:string;
    firstName: string;
    lastName: string;
    number: string;
    cvc: string;
    expiryMonth: string;
    expiryYear: string;
    aToken: string;
    oId: string;
    jsTranType: string;
  };
}



export const RedirectSilverExpressHandler: React.FC<SilverExpressFormProps> = ({ result }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${process.env.REACT_APP_SILVER_EXRESS_JS_PATH}/js/silverexpress-pay.min.js`;
    script.async = true;
    console.log("Debug" ,  `${process.env.REACT_APP_SILVER_EXRESS_JS_PATH}/js/silverexpress-pay.min.js`)
    script.onload = () => {
      const waitForSDK = setInterval(() => {
        console.log(window.Silverexpress);
        if (window.Silverexpress) {
          clearInterval(waitForSDK);
          // Proceed with the logic

          const payload = result;
          console.log("payload", JSON.stringify(payload));
          const {type , ...rest} = payload;
          const silver_express_payload = {...rest , jsUrl:process.env.REACT_APP_SILVER_EXRESS_API_PATH}
          console.log("silver_express_payload", JSON.stringify(silver_express_payload));
          new window.Silverexpress(silver_express_payload, (res: any) => {
            console.log("res", res)
            if (res) {
              if (res.status === 'SUCCESS' && res.successMsg?.resultCode === '10000') {
                const url = decodeURIComponent(res.successMsg.redirectUrl);
                //window.location.href = url;
                setIsLoading(false);
              } else {
                alert(`Payment failed: ${res.status}`);
                setIsLoading(false);
              }
            }
          });
        }
      }, 100);
      // Timeout in case SDK never loads
      setTimeout(() => {
        clearInterval(waitForSDK);
        if (!window.Silverexpress) {
          console.error('SilverExpress SDK not loaded after timeout.');
          setIsLoading(false);
        }
      }, 5000);

    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [result]);

  return (
    <>
      {isLoading && (<Loader />)}
    </>
  );
};

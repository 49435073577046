/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LANGUAGES } from "../../constants/languages.c";
import "./style.css";
import Loader from "../../components/loader/Loader.component";
import Api from "../../utils/Api";
import SuccessBox from "./SuccessBox";
import { REDIRECT_COUNT, REDIRECT_COUNT_FOR_IBEC } from "../../constants/timeout-intervals";
import { BASE_API_URL } from "../../constants/api-path.c";
import { useGoogleAnalyticsPageView, useGoogleAnalyticsClickEvent, categoryGA } from "../../components/hooks/google-analytics";
import useIbec from "../../components/hooks/useIBEC";

export default function SuccessfulPage() {
  useGoogleAnalyticsPageView(); // For google analytics
  const pushClickEventAnalytics = useGoogleAnalyticsClickEvent();  // For google analytics
  pushClickEventAnalytics(categoryGA.paymentSuccess, ` ${localStorage.getItem("slug")}`); // For google analytics
  const { transaction }: any = useParams();
  const [transactionDetail, setTransactionDetail] = useState<any>("");
  const [successURL, setSuccessURL] = useState<any>("");
  const slug = localStorage.getItem("slug");
  const fetchData = () => {
    Api()
      .post(`${BASE_API_URL}/payment/details`, {
        URLKey: localStorage.getItem("slug"),
        transactionId: transaction,
      })
      .then((res) => setTransactionDetail(res));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const { isIbec } = useIbec(transactionDetail);

  const COUNTER = isIbec ? REDIRECT_COUNT_FOR_IBEC.SUCCESS : REDIRECT_COUNT.SUCCESS;

  const navigate = useNavigate();
  let language = localStorage.getItem("lang");
  useEffect(() => {
    let originalURL = window.location.pathname;
    let modifiedURL;
    if (language === LANGUAGES.EN) {
      modifiedURL = originalURL.replace("/jp/", "/");
      navigate(modifiedURL);
    }
    const interval = setInterval(() => { }, 1);
    return () => {
      clearInterval(interval);
      window.location.reload();
    };
  }, [language, navigate]);

  useEffect(() => {
    //console.log("transactionDetail" , transactionDetail)
    const success_url = transactionDetail?.return_url?.success_url?.includes("?")
      ? `${transactionDetail?.return_url?.success_url}&transaction_reference=${transactionDetail?.Ref}&transaction_id=${transactionDetail?.Ref}`
      : `${transactionDetail?.return_url?.success_url}?transaction_reference=${transactionDetail?.Ref}&transaction_id=${transactionDetail?.Ref}`;
    setSuccessURL(success_url);
    //console.log("success_url" , success_url , "successURL" , successURL)
    setTimeout(() => {

      if (transactionDetail?.return_url?.success_url) {

        window.location.href = success_url//transactionDetail?.return_url?.success_url;
      }
    }, COUNTER * 1000);
  }, [transactionDetail?.return_url?.success_url]);


  if (!transactionDetail) {
    return <Loader />;
  } else
    return (
      <>
        <SuccessBox
          Merchant={transactionDetail?.paymentDetail?.Merchant}
          success_url={transactionDetail?.return_url?.success_url ? successURL : ""}
          amount={transactionDetail?.paymentDetail?.Amount?.value}
          currencyCode={transactionDetail?.paymentDetail?.Amount?.currencyCode}
          Ref={transactionDetail?.Ref}
          transactionId={transaction}
          language={language}
          slug={slug}
          apiData={transactionDetail}
          counter={COUNTER}
          isIbec={isIbec}
        />
      </>
    );
}
